@mixin wider-then ($size) {
  @media (min-width: $size) {
    @content
  }
}
@mixin wider-then-phone () {
  @include wider-then($max-width-phone) {
    @content
  }
}
@mixin wider-then-tablet () {
  @include wider-then($max-width-tablet) {
    @content
  }
}
@mixin not-selectable () {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}

@mixin multi-line-truncate ($n:3) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $n;
  overflow: hidden;
}

@mixin centered () {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
