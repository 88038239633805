





















































































.vimeo-player-box {
  position: relative;
  width: 100%;
  height: 100%;

  .video-player {
    width: 100%;
    height: 100%;
  }
  iframe {
    width: 100%;
    height: 100%;
  }
}
.vimeo-player-box.player-aspect-ratio {
  .video-player {
    position: absolute;
    top: 0;
    left: 0;
  }
}
