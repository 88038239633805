












































































































































































.attachments-view {
  .attachment-box {
    img {
      object-fit: contain;
      // object-position: left;
      &.loaded {
        background-color: transparent;
      }
    }
  }
  .embed-html-box {
    width: 100%;
    height: 100%;

    background: #ccc;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: opacity 0.2s;

      &.loaded {
        opacity: 1;
      }
    }
  }
  .caption {
    --padding: 0.5em;
    --l-height: 1.5em;
    position: absolute;
    bottom: calc((var(--l-height) + var(--padding) * 2) * -1);
    line-height: var(--l-height);
    padding: var(--padding);
    background-color: rgba(222, 216, 217, 0.91);
  }
}
