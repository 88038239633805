$base-size: 48rem / 16; // 48px (3rem) based on default font size 16px
$base-padding: $base-size / 3;
$small-padding: $base-size / 6;
$input-margin: $base-size / 4;
$border-thick-w: 5px;

$max-width-phone: 30rem; // = 480px
$max-width-tablet: 64rem; // = 1024px

$max-width-text-block: 38rem; // = 608px
$min-width-sidebar: 16rem;
$max-width-sidebar: 20rem;

$max-width-text-block-px: 38 * 16px;
$base-padding-px: 16px;

$color-bg: #ffdce3;
$color-bg-semitransparent: transparentize($color-bg, 0.2);
$color-white-semitransparent: transparentize(white, 0.3);

$color-aba-blue: #0000ff;
$color-aba-red: #860025;
$color-aba-blue-semi: rgba(0, 68, 255, 0.44);
$color-dimmed: #999999;

$text-color: #373737;

$z-index-header: 40;
$z-high: 500;

$transition-time: 0.2s;

$logo-ratio: 1.85;

$h1: $base-size;
$h2: $base-size * 0.75;
$h3: $base-size * 0.5;
$h4: $base-size * 0.45;
$font-size-caption: 12px;

$font-family-sans-serif:
  // Safari for macOS and iOS (San Francisco)
  -apple-system,
  // Chrome < 56 for macOS (San Francisco)
  BlinkMacSystemFont,
  // Windows
  "Segoe UI",
  // Android
  "Roboto",
  // Basic web fallback
  "Helvetica Neue", Arial, sans-serif,
  // Emoji fonts
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
