

































































































@import "../../../styles/vars";

.popover-modal.modal-post {
  top: unset;
  left: unset;
  transform: none;

  .content-box {
    position: relative;
    height: 100%;
    //width: 100%;
    // max-height: calc(100vh - (#{$base-padding} - #{$border-thick-w}) * 2);
    //padding: $base-padding;
    // border: $border-thick-w solid #888888;

    & > header {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1000;
      button {
        border-radius: 50%;
        background: rgba(240, 240, 240, 0.3);
      }
    }
  }
  .modal-shadow {
    background: rgba(215, 215, 215, 0.85);
  }
  .bg-semi-mix {
    background: #dfd8d9e8;
  }
}
