











































































































































































































.event {
  .list-string {
    & >*:not(:last-child) {
      &:after {
        content: ", ";
      }
    }
  }
}
